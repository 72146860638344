import React, {useEffect} from "react"

import Layout from "./Layout"
import Seo from "./Seo";//TODO - set title below

import 'typeface-roboto'

import { App } from "@capacitor/app";


const Wrapper = ({ children, ...props }) => {
    useEffect(() => {
        App.addListener('backButton', () => {
            window.history.back();

        });
    });

    return (
        <Layout {...props}>
            <Seo title={`${props.entity?.title || props.entity?.identifier || 'Home'}`} />
            {children}
        </Layout>
    )
}


export default Wrapper