import React, { Fragment, useCallback, useEffect } from "react"
import { styled } from "@mui/material/styles"
import _ from "lodash"

import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { getEntityUrl, getAccessoryIcon } from "../../util"

import Menu from "../imagelist/menu"
import EntityKeypad from "../../components/Keypad"

import Wrapper from "../../components/Wrapper"

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useLocalStorage } from '../../components/hooks';

const PREFIX = "List"

const classes = {
  listItem: `${PREFIX}-listItem`,
  listLink: `${PREFIX}-listLink`,
  listImg: `${PREFIX}-listImg`,
  titleDiv: `${PREFIX}-titleDiv`,
  titleFlex: `${PREFIX}-titleFlex`,
  title1: `${PREFIX}-title1`,
  title2: `${PREFIX}-title2`,
  title3: `${PREFIX}-title3`,
  numCode: `${PREFIX}-numCode`,
  playButton: `${PREFIX}-playButton`
}

const Root = styled("ul")(({ theme }) => ({
  listStyleType: "none",
  margin: "0",
  marginTop: "0px",
  padding: 0,
  [`& .${classes.listItem}`]: {
    textDecoration: "none",
    display: "block",
    cursor: "pointer",
    //padding: "5px 0px",
    color: "#fff",
    background: theme.palette.mode === "light" ? "#191d1e" : "#ffffff"  ,
    listStyle: "none",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    minHeight: "48px",
    margin: "0",
    overflow: "hidden",
    alignItems: "center",
    borderBottom: "10px solid #000",
  },

  [`& .${classes.listLink}`]: {
    textDecoration: "none",
    // color: '#000',
  },

  /* STANDART
    listImg: {
        maxWidth: '110px',
        display: 'flex',

    },*/

  /* CUSTOM 1 */

  [`& .${classes.listImg}`]: {
    width: "110px",
    height: "110px",
    minHeight: "110px",
    minWidth: "110px",
    display: "flex",
    padding:"5px",
    flexDirection: "column",
  },

  /* End CUSTOM 1*/

  [`& .${classes.titleDiv}`]: {
    width: "100%",
    overflow: "hidden",
    padding: "5px 15px 15px 5px"
  },

  [`& .${classes.titleFlex}`]: {
    display: "flex",
    flexGrow: "1",
    width: "100%",
    alignItems: "center",
    position: "relative",
    minHeight: "60px",
    marginBottom: '6px',
  },

  [`& .${classes.title1}`]: {
    fontSize: '17px',
    textTransform: "none",
    marginBottom: "0",
    lineHeight: "1.15",
    fontWeight: "normal",
    fontFamily: 'opensans',
  },

  [`& .${classes.title2}`]: {
    fontSize: '17px',
    fontFamily: 'opensans',
    lineHeight: 1.2,
    marginTop: 4,
    paddingRight: 20,
  },

  [`& .${classes.title3}`]: {
    fontSize: "14px",
    marginBottom: "0px",
    marginTop: "2px",
    fontWeight: "normal"
  },

  [`& .${classes.numCode}`]: {
    position: 'absolute',
    right: 15,
    bottom: 0,
    fontFamily: 'opensans',
    fontSize: 13,
    fontWeight: 'bold',
    display: 'block',
  },

  [`& .${classes.playButton}`]: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    // filter: "invert(1)",
  }
}))

const List = ({ entity, pageContext }) => {
  const { strapiChildren: entities } = entity

  const { defaultLocale, locale } = pageContext

  const [scrollPos, setScrollPos] = useLocalStorage(`module.kima-list-scrollpos-${locale}.${entity.identifier}`, 0);
  const onNavigate = useCallback((e) => {
    setScrollPos(e.view.pageYOffset);
  }, [setScrollPos]);

  useEffect(() => {
    window.scrollTo(0, scrollPos);
  }, [scrollPos]);

  return (
    <Root>
      {entities.map((entity, n) => {
        const listRole = _.find(entity.Roles, {
          strapi_component: "role.list-child"
        })

        const title1 = listRole?.line1 || entity.title || ""
        const title2 = listRole?.line2 || false
        const title3 = listRole?.line3 || false

        const numCode = parseInt(listRole?.numCode || 0) || ""

        const AccessoryIcon = getAccessoryIcon({ defaultIcon: ArrowForwardIosIcon, role: listRole });

        const image = entity.smallImage
          ? getImage(entity.smallImage.localFile)
          : null;

        return (
          <Fragment key={entity.identifier}>
            <Link
              to={getEntityUrl({ entity, defaultLocale })}
              className={classes.listLink}
              onClick={onNavigate}
            >
              <li key={entity.identifier} className={classes.listItem}>
                {image !== null ? (
                  <GatsbyImage
                    className={classes.listImg}
                    image={image}
                    alt={title1}
                    layout="fullWidth"
                  />
                ) : (
                  <></>
                )}
                <div className={classes.titleFlex}>
                  <div className={classes.titleDiv}>
                    <div className={classes.title1}>{title1}</div>
                    <div className={classes.title2}>{title2}</div>
                    <div className={classes.title3}>{title3}</div>
                    <div className={classes.numCode}>{numCode}</div>
                    <div className={classes.playButton}>
                      <AccessoryIcon color="#fff" style={{fill: 'default'}} fontSize="small" />
                    </div>
                  </div>
                </div>
              </li>
            </Link>
          </Fragment>
        )
      })}
    </Root>
  )
}

export default List
