import React from "react";
import { styled } from '@mui/material/styles';
import _ from "lodash";

import { BgImage } from "gbimage-bridge"
import { getImage } from "gatsby-plugin-image"

import { Link } from "gatsby"

import { getEntityUrl, getOverlayImages } from "../../util";
import Wrapper from "../../components/Wrapper";

import Topbar from "./topbar";

import "core-js/proposals/string-match-all";//polyfill for old Androids; TODO - move away from here

const PREFIX = 'EntityPage';

const classes = {
    bildbutton: `${PREFIX}-bildbutton`,
    arrow: `${PREFIX}-arrow`,
    bHeadline: `${PREFIX}-bHeadline`
};

const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.bildbutton}`]: {
        display: 'block',
        position: 'absolute',
        /* font-family: 'openlight', */
        bottom: '0px',
        left: '0px',
        whiteSpace: 'nowrap',
        padding: '8px 15px',
        background: 'rgba(0, 0, 0, 0.6)',
        fontSize: '20px',
        color: '#ffffff!important',
        border: '0px solid #FFF',
        // letterSpacing: '0.06em',
        //textDecoration: 'none!important',
        textTransform: 'uppercase',
        width: '100%',
        /* Custom 1 */
        //  boxShadow: '0 0 0 7px rgba(183, 183, 183, 0.5), 0 0 0 21px rgba(201, 201, 201, 0.3), 0 0 0 63px rgba(211, 211, 211, 0.2), 0 0 0 186px rgba(201, 201, 201, 0.1), 0 0 0 558px rgba(190, 190, 190, 0.1)',
        // borderRadius: '50%',
        // padding: '7px 55px 7px 55px',
        /* End Custom 1 */
    },

    [`& .${classes.arrow}`] : {
        position: 'absolute',
        right: '10px',
        top: '50%',
        transform: 'translateY(-50%)',
        filter: 'invert(1)',
    },

    [`& .${classes.bHeadline}`]: {
        position: 'absolute',
        bottom: '62px',
        left: '11px',
        /* webkitTransform: 'translateX(-50%)', */
        /* transform: 'translateX(-50%)', */
        /*whiteSpace: 'nowrap',*/
        width: 'fit-content',
        margin: '0',
        /* text-align: 'center', */
        fontSize: '22px',
        color: '#ffffff !important',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        textShadow: '2px 2px 3px hsl(0deg 0% 0% / 48%)',
        fontFamily: 'opensans',
        letterSpacing: '0',
        lineHeight: '1.1',
    }
}));

const EntityPage = (props) => {

    const { entity, pageContext, selectedModule, files } = props;
    const { strapiChildren: children } = entity;


    return <Root className="lmvh">
        <Wrapper {...props} topbar={Topbar} title={entity.title} transparentAppbar={true}>
            {/*<MainMenu {...props} />*/}
            <div class="" style={{ marginTop: '-56px', overflowX: 'hidden' }/*TODO - var*/}>
                {children.map((child, n) => {

                    if (child.smallImage) {
                        const childImg = getImage(child.smallImage.localFile);

                        const listRole = _.find(child.Roles, { strapi_component: 'role.list-child' });
                        const overlays = getOverlayImages(listRole, files);

                        const title1 = listRole?.line1 || child.title || '';
                        const title2 = listRole?.line2 || false;

                        const { defaultLocale } = pageContext;
                        const url = getEntityUrl({ entity: child, defaultLocale });

                        return <Link to={url}>
                            <BgImage image={childImg} style={{ height: n === 0 ? "50vh" : "50vh", flexGrow: 0, backgroundSize: "cover" }}>
                                {overlays?.map(o => o)/* TODO - test*/}
                                {title2 !== false &&
                                    <div className={classes.bHeadline}>{title2}</div>}

                                <div className={classes.bildbutton}>{title1}<img className={classes.arrow} src={`/images/smallArrow.png`} alt="play" /></div>
                            </BgImage>
                        </Link>
                    } else {
                        return <></>;
                    }
                })}
            </div>
        </Wrapper>
    </Root>
}

export default EntityPage;